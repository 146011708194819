<template>
  <div class="box box--mainBlock">
    <div class="box__decor box__decor--1"></div>
    <div class="box__decor box__decor--2"></div>
    <div class="box__inner">
      <div class="mainBlock">
        <h1 class="mainBlock__slogan">FLYJETS</h1>
        <vue-scroll :ops="ops" class="homePageWrapper">
          <div class="homePage">
            <div class="homePage__category">
              <div class="homePage__header" @click="toogleMenu">Map and Search</div>
              <div class="homePage__table">
                <div class="homePage__table-wrapper">
                  <router-link
                    v-for="(item, index) of map"
                    :key="index"
                    class="homePage__table-row"
                    :to="item.href"
                  >
                    <div class="homePage__table-cell homePage__table-cell--number">{{index + 1}}</div>
                    <div class="homePage__table-cell homePage__table-cell--name">{{item.title}}</div>
                  </router-link>
                </div>
              </div>
            </div>
            <div class="homePage__category">
              <div class="homePage__header" @click="toogleMenu">Booking</div>
              <div class="homePage__table">
                <div class="homePage__table-wrapper">
                  <router-link
                    v-for="(item, index) of booking"
                    :key="index"
                    class="homePage__table-row"
                    :to="item.href"
                  >
                    <div class="homePage__table-cell homePage__table-cell--number">{{index + 1}}</div>
                    <div class="homePage__table-cell homePage__table-cell--name">{{item.title}}</div>
                  </router-link>
                </div>
              </div>
            </div>
            <div class="homePage__category">
              <div class="homePage__header" @click="toogleMenu">Dashboard for flyer</div>
              <div class="homePage__table">
                <div class="homePage__table-wrapper">
                  <router-link
                    v-for="(item, index) of dashboardFlyer"
                    :key="index"
                    class="homePage__table-row"
                    :to="item.href"
                  >
                    <div class="homePage__table-cell homePage__table-cell--number">{{index + 1}}</div>
                    <div class="homePage__table-cell homePage__table-cell--name">{{item.title}}</div>
                  </router-link>
                </div>
              </div>
            </div>
            <div class="homePage__category">
              <div class="homePage__header" @click="toogleMenu">Dashboard for provider</div>
              <div class="homePage__table">
                <div class="homePage__table-wrapper">
                  <router-link
                    v-for="(item, index) of dashboardProvider"
                    :key="index"
                    class="homePage__table-row"
                    :to="item.href"
                  >
                    <div class="homePage__table-cell homePage__table-cell--number">{{index + 1}}</div>
                    <div class="homePage__table-cell homePage__table-cell--name">{{item.title}}</div>
                  </router-link>
                </div>
              </div>
            </div>
            <div class="homePage__category">
              <div class="homePage__header" @click="toogleMenu">Dashboard for admin</div>
              <div class="homePage__table">
                <div class="homePage__table-wrapper">
                  <router-link
                    v-for="(item, index) of dashboardAdmin"
                    :key="index"
                    class="homePage__table-row"
                    :to="item.href"
                  >
                    <div class="homePage__table-cell homePage__table-cell--number">{{index + 1}}</div>
                    <div class="homePage__table-cell homePage__table-cell--name">{{item.title}}</div>
                  </router-link>
                </div>
              </div>
            </div>
            <div class="homePage__category">
              <div class="homePage__header" @click="toogleMenu">Info section</div>
              <div class="homePage__table">
                <div class="homePage__table-wrapper">
                  <router-link
                    v-for="(item, index) of infoSections"
                    :key="index"
                    class="homePage__table-row"
                    :to="item.href"
                  >
                    <div class="homePage__table-cell homePage__table-cell--number">{{index + 1}}</div>
                    <div class="homePage__table-cell homePage__table-cell--name">{{item.title}}</div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </vue-scroll>
      </div>
    </div>
  </div>
</template>

<script>
  import '@/views/main/mainBlock/mainBlock.styl'
  import './HomePage.styl'

  export default {
    name: 'HomePage',
    data: () => ({
      map: [
        {
          title: 'Main Page',
          href: '/',
        }, {
          title: 'Reset password',
          href: '/reset-password',
        }, {
          title: 'Map Search',
          href: '/map-search',
        }, {
          title: 'Manually Request',
          href: '/manually-request',
        }, {
          title: 'Search Results',
          href: '/search-results',
        }, {
          title: 'Aircraft Detail',
          href: '/aircraft-detail',
        }, {
          title: 'Aircraft Detailbid',
          href: '/aircraft-detailbid',
        }, {
          title: 'Auction Bidding',
          href: '/auction-bidding',
        },
      ],
      booking: [
        {
          title: 'Flight',
          href: '/booking/flights',
        }, {
          title: 'Aircraft',
          href: '/booking/aircraft',
        }, {
          title: 'Travelers',
          href: '/booking/travelers',
        }, {
          title: 'Terms',
          href: '/booking/terms',
        }, {
          title: 'Fly Green',
          href: '/booking/fly-green',
        }, {
          title: 'Summary',
          href: '/booking/payment',
        }, {
          title: 'Confirm',
          href: '/booking/confirmation',
        },
      ],
      dashboardFlyer: [
        {
          title: 'Dashboard',
          href: '/dashboard',
        }, {
          title: 'Profile',
          href: '/dashboard/profile',
        }, {
          title: 'Booking',
          href: '/dashboard/booking',
        }, {
          title: 'Flights',
          href: '/dashboard/flights',
        }, {
          title: 'Manual request',
          href: '/dashboard/manual-request',
        }, {
          title: 'Settings',
          href: '/dashboard/settings',
        }, {
          title: 'Passenger identification',
          href: '/dashboard/passenger-id',
        }, {
          title: 'Fly rewards',
          href: '/dashboard/flyrewards',
        }, {
          title: 'Payment information',
          href: '/dashboard/payment',
        }, {
          title: 'Route alerts',
          href: '/dashboard/alerts',
        }, {
          title: 'Auctions (bids)',
          href: '/dashboard/auctions',
        }, {
          title: 'Fly Empty',
          href: '/dashboard/requests',
        }, {
          title: 'Sell spaces',
          href: '/dashboard/seats',
        }, {
          title: 'Messages',
          href: '/dashboard/messages',
        },
      ],
      dashboardProvider: [
        {
          title: 'Dashboard',
          href: '/provider-dashboard',
        }, {
          title: 'Profile',
          href: '/provider-dashboard/profile',
        }, {
          title: 'Personnel',
          href: '/provider-dashboard/personnel',
        }, {
          title: 'Fleet',
          href: '/provider-dashboard/fleet',
        }, {
          title: 'New aircraft',
          href: '/provider-dashboard/new-aircraft',
        }, {
          title: 'Charter Uploads',
          href: '/provider-dashboard/charter-uploads',
        }, {
          title: 'Flight Uploads',
          href: '/provider-dashboard/flight-uploads',
        }, {
          title: 'Booking',
          href: '/provider-dashboard/booking',
        }, {
          title: 'Booking confirmation',
          href: '/provider-dashboard/booking-confirmations',
        }, {
          title: 'Flights',
          href: '/provider-dashboard/flights',
        }, {
          title: 'Flights detail',
          href: '/provider-dashboard/flights/detail',
        }, {
          title: 'Fly rewards',
          href: '/provider-dashboard/flyrewards',
        }, {
          title: 'Payment information',
          href: '/provider-dashboard/payment',
        }, {
          title: 'Messages',
          href: '/provider-dashboard/messages',
        }, {
          title: 'Requests',
          href: '/provider-dashboard/requests',
        },
      ],
      dashboardAdmin: [
        {
          title: 'Dashboard',
          href: '/admin',
        }, {
          title: 'Profile',
          href: '/admin/profile',
        }, {
          title: 'Settings',
          href: '/admin/settings',
        }, {
          title: 'Network activity',
          href: '/admin/network',
        }, {
          title: 'Aircraft provider requests',
          href: '/admin/provider-sign-ups',
        }, {
          title: 'Flight requests',
          href: '/admin/requests',
        }, {
          title: 'Flights: current, historical and upcoming',
          href: '/admin/flights',
        }, {
          title: 'Booking list',
          href: '/admin/booking',
        }, {
          title: 'Blog',
          href: '/admin/blog',
        }, {
          title: 'Confirm manual booking',
          href: '/admin/booking-confirmations',
        }, {
          title: 'Aircraft uploads',
          href: '/admin/aircraft-uploads',
        }, {
          title: 'New aircraft',
          href: '/admin/new-aircraft',
        }, {
          title: 'Charter uploads',
          href: '/admin/charter-uploads',
        }, {
          title: 'Flight uploads',
          href: '/admin/flight-uploads',
        }, {
          title: 'Flyrewards uploads',
          href: '/admin/flyrewards',
        }, {
          title: 'Messages',
          href: '/admin/messages',
        }, {
          title: 'Argus statistic',
          href: '/admin/argus-statistic',
        },
        {
          title: 'TFR sign ups',
          href: '/admin/tfr-sign-ups',
        },
        {
          title: 'Subscription Analytics',
          href: '/admin/subscription-analytics',
        },
      ],
      infoSections: [
        {
          title: 'Disclaimer',
          href: '/disclaimer',
        }, {
          title: 'Privacy Policy',
          href: '/privacy-policy',
        }, {
          title: 'Terms and Conditions',
          href: '/terms-conditions',
        }, {
          title: 'About us',
          href: '/about',
        }, {
          title: 'Contact',
          href: '/contact',
        }, {
          title: 'Contact (without WhatsApp)',
          href: '/contact2',
        }, {
          title: 'Blog',
          href: '/blog',
        }, {
          title: 'BlogArticle',
          href: '/article',
        },
      ],
      ops: {
        rail: {
          size: '3px',
        },
        bar: {
          size: '3px',
          background: '#bdbaba',
        },
      },
    }),
    methods: {
      toogleMenu (event) {
        const item = event.currentTarget.closest('.homePage__category').querySelector('.homePage__table')
        this.$root.animations.slideToggle(item)
      },
    },
    metaInfo: {
      meta: [{
        name: 'robots', content: 'noindex',
      }],
    },
  }
</script>
